// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------
/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
}
/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::after,
*::before {
  box-sizing: inherit;
}
/**
 * Basic styles for links
 */
a {
  color: $brand-color;
  text-decoration: none;
  @include on-event {
    color: $text-color;
    text-decoration: underline;
  }
}
// Global Components
body {
  overflow-x: hidden;
  @include body-font;
}

.text-description {
  text-align: left;
  font-size: 130% !important;
}

.text-list {
  float:center;
  font-size: 120% !important;
  line-height: 220% !important;

  margin-left: 15%;
  @media only screen and (max-width: 1100px) {
    margin-left: 10%;
  }
  @media only screen and (max-width: 750px) {
    margin-left: 2%;
  }
  @media only screen and (max-width: 400px) {
    margin-left: -8%;
  }
}

.text-muted {
  font-size: 120% !important;
  color: $beehive-gray-brown;
}

.text-muted-plus {
  color: lighten($beehive-gray-brown, 10%);
}

.text-muted-blue {
  color: lighten($beehive-light-blue, 30%);
}

.text-primary {
  color: $theme-primary;
}

.text-center {
  text-align: center !important;
}

p {
  font-size: 14px;
  line-height: 1.75;
}

p.large {
  font-size: 16px;
}

a,
a.active,
a:active,
a:focus,
a:hover {
  outline: none;
}

a {
  color: $theme-primary;
}

a.active,
a:active,
a:focus,
a:hover {
  color: darken($theme-primary, 20%);
}


.content-link
{
  &:focus,
  &:hover,
  &:active{
    color: $beehive-orange;
  }
  color: $beehive-orange;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include heading-font;
  font-weight: 700;
}

.img-centered {
  margin: 0 auto;
}

.bg-light-gray {
  background-color: lighten($beehive-light-blue, 30%);
}

.bg-darkest-gray {
  background-color: darken($beehive-light-blue, 30%);
}

// 限制內容最大寬度，當螢幕寬度小於1024px最大寬度就是100%
.container
{
  width:80% !important;
  @media only screen and (max-width: 1024px) {
    width:100% !important;
  }
}
