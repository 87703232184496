// Timeline

.timeline {
  list-style: none;
  padding: 0;
  position: relative;

  &:before {
    top: 0;
    bottom: 0;
    position: absolute;
    content: "";
    width: 2px;
    background-color: $beehive-lighter-brown;
    left: 40px;
    margin-left: -1.5px;
  }

  > li {
    margin-bottom: 50px;
    position: relative;
    min-height: 50px;

    &:after,
    &:before {
      content: " ";
      display: table;
    }

    &:after {
      clear: both;
    }

    .timeline-panel {
      width: 100%;
      float: right;
      padding: 0 20px 0 100px;
      position: relative;
      text-align: left;

      &:before {
        border-left-width: 0;
        border-right-width: 15px;
        left: -15px;
        right: auto;
      }

      &:after {
        border-left-width: 0;
        border-right-width: 14px;
        left: -14px;
        right: auto;
      }
    }

    .timeline-image {
      left: 0;
      margin-left: 0;
      width: 80px;
      height: 80px;
      position: absolute;
      z-index: 100;
      background-color: $theme-primary;
      color: white;
      border-radius: 100%;
      border: 7px solid $beehive-lighter-brown;
      text-align: center;

      h4 {
        font-size: 10px;
        margin-top: 12px;
        line-height: 14px;
      }
    }

    &.timeline-inverted > .timeline-panel {
      float: right;
      text-align: left;
      padding: 0 20px 0 100px;

      &:before {
        border-left-width: 0;
        border-right-width: 15px;
        left: -15px;
        right: auto;
      }

      &:after {
        border-left-width: 0;
        border-right-width: 14px;
        left: -14px;
        right: auto;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .timeline-heading {
    h4 {
      margin-top: 0;
      color: inherit;

      &.subheading {
        text-transform: none;
      }
    }
  }

  .timeline-body {
     > ul,
    > p {
      margin-bottom: 0;
    }
  }
}
@media(min-width:768px) {
  .timeline {
    &:before {
      left: 50%;
    }

    > li {
      margin-bottom: 100px;
      min-height: 100px;

      .timeline-panel {
        width: 41%;
        float: left;
        padding: 0 20px 20px 30px;
        text-align: right;
      }

      .timeline-image {
        width: 100px;
        height: 100px;
        left: 50%;
        margin-left: -50px;

        h4 {
          font-size: 13px;
          margin-top: 16px;
          line-height: 18px;
        }
      }

      &.timeline-inverted > .timeline-panel {
        float: right;
        text-align: left;
        padding: 0 30px 20px 20px;
      }
    }
  }
}
@media(min-width:992px) {
  .timeline {
    > li {
      min-height: 150px;

      .timeline-panel {
        padding: 0 20px 20px;
      }

      .timeline-image {
        width: 150px;
        height: 150px;
        margin-left: -75px;

        h4 {
          font-size: 18px;
          margin-top: 30px;
          line-height: 26px;
        }
      }

      &.timeline-inverted > .timeline-panel {
        padding: 0 20px 20px;
      }
    }
  }
}
@media(min-width:1200px) {
  .timeline {
    > li {
      min-height: 170px;

      .timeline-panel {
        padding: 0 20px 20px 100px;
      }

      .timeline-image {
        width: 170px;
        height: 170px;
        margin-left: -85px;

        h4 {
          margin-top: 40px;
        }
      }

      &.timeline-inverted > .timeline-panel {
        padding: 0 100px 20px 20px;
      }
    }
  }
}
