// Contact Section

section#contact {
  background-color: $beehive-light-blue;
  background-image: url('../images/map-image.png');
  background-position: center;
  background-repeat: no-repeat;

  .section-heading {
    color: white;
  }

  h3.contact-title {
    color: white;
  }

  .form-group {
    margin-bottom: 25px;

    input,
    textarea {
      padding: 10px;
    }

    input.form-control {
      height: auto;
    }

    textarea.form-control {
      height: 180px;
    }
  }

  .form-control:focus {
    border-color: $theme-primary;
    box-shadow: none;
  }

  ::-webkit-input-placeholder {
    @include heading-font;
    font-weight: 700;
    color: $gray-lighter;
  }

  :-moz-placeholder {
    /* Firefox 18- */
    @include heading-font;
    font-weight: 700;
    color: $gray-lighter;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    @include heading-font;
    font-weight: 700;
    color: $gray-lighter;
  }

  :-ms-input-placeholder {
    @include heading-font;
    font-weight: 700;
    color: $gray-lighter;
  }

  .text-danger {
    color: $theme-danger;
  }
}
// Global Section Styles

section {
  padding: 100px 0;

  h2.section-heading {
    font-size: 40px;
    margin-top: 0;
    margin-bottom: 20px;
    padding-bottom: 10px;

    &:before {
      position: absolute;
      top: 60px;
      content: "";
      width: 50px;
      margin-left: -25px;
      height: 2px;
      left: 50%;
      background-color: $beehive-dark-yellow;
    }
  }

  h3.section-subheading {
    font-size: 18px;
    @include serif-font;
    text-transform: none;
    font-weight: 400;
    margin-bottom: 75px;
    line-height: 1.5;
  }

  p {
    margin-bottom: 20px;
  }
}

@media(min-width:768px) {
  section {
    padding: 150px 0;
  }
}

// Services Section
.service-heading {
  margin: 15px 0;
  text-transform: none;
}

// Portfolio Section
#portfolio {
  .portfolio-item {
    margin: 0 0 15px;
    right: 0;

    .portfolio-link {
      display: block;
      position: relative;
      max-width: 400px;
      max-height: 200px;
      margin: 0 auto;
      overflow: hidden;

      .portfolio-hover {
        background: fade-out($beehive-dark-brown, .2);
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: all ease 0.5s;
        -webkit-transition: all ease 0.5s;
        -moz-transition: all ease 0.5s;

        &:hover {
          opacity: 1;
        }

        .portfolio-hover-content {
          position: absolute;
          width: 100%;
          height: 20px;
          font-size: 20px;
          text-align: center;
          top: 50%;
          margin-top: -12px;
          color: white;

          i {
            margin-top: -12px;
          }

          h3,
          h4 {
            margin: 0;
          }
        }
      }
    }

    .portfolio-caption {
      background-color: white;
      height: 170px;
      max-width: 400px;
      margin: 0 auto;
      padding: 25px;
      text-align: center;

      h4 {
        text-transform: none;
        margin: 0 0 15px 0;
      }

      p {
        @include serif-font;
        font-size: 16px;
        margin: 0;
      }
    }
  }

  * {
    z-index: 2;
  }
}
@media(min-width:767px) {
  #portfolio {
    .portfolio-item {
      margin: 0 0 30px;
    }
  }
}
// Team Section
.team-member {
  text-align: center;
  margin-bottom: 50px;

  img {
    margin: 0 auto;
    border: 7px solid white;
  }

  h4 {
    margin-top: 25px;
    margin-bottom: 0;
    text-transform: none;
  }

  p {
    margin-top: 0;
  }
}
