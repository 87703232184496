
.logo-partner-title
{
    text-transform: uppercase;
    text-align: center;
    font-size: 110%;
    font-weight: bold;
}

.logo-partner
{
    margin-bottom: 30px;
    max-width:180px;
}

.logo-beehive
{
    max-width: 350px;
}

.logo-footer
{
    max-width: 250px;
}