// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------
// Custom Navigation Bar

.navbar-custom {
  background-color: $beehive-light-blue;
  border-color: transparent;

  .navbar-brand {
    color: white;
    @include heading-font;

    &.active,
    &:active,
    &:focus,
    &:hover {
      color: $beehive-normal-yellow;
    }
  }

  .navbar-collapse {
    border-color: fade-out(white, .02);
  }

  .navbar-toggle {
    background-color: $theme-primary;
    border-color: $theme-primary;
    @include heading-font;
    color: white;
    font-size: 12px;

    &:focus,
    &:hover {
      background-color: $theme-primary;
    }
  }

  .nav {
    li {
      a {
        @include heading-font;
        font-weight: 400;
        font-size: 1.5rem;
        letter-spacing: 1px;
        color: white;

        &:focus,
        &:hover {
          color: $theme-primary;
          outline: none;
        }
      }
    }
  }

  .navbar-nav > .active > a {
    border-radius: 0;
    color: white;
    background-color: $theme-primary;
  }

  .navbar-nav > .active > a:focus,
  .navbar-nav > .active > a:hover {
    color: white;
    background-color: darken($theme-primary, 10%);
  }
}
@media(min-width:768px) {
  .navbar-custom {
    background-color: transparent;
    padding: 25px 0;
    -webkit-transition: padding 0.3s;
    -moz-transition: padding 0.3s;
    transition: padding 0.3s;
    border: none;

    .navbar-brand {
      font-size: 2em;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      transition: all 0.3s;
    }

    .navbar-nav > .active > a {
      border-radius: 3px;
    }
  }
}
// Navbar Change on Scroll
@media(min-width:768px) {
  .navbar-custom.affix {
    background-color: $beehive-light-blue;
    padding: 10px 0;

    .navbar-brand {
      font-size: 2.5rem;
    }
  }
}

header {
  background-image: url('../images/header-bg.jpg');
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  @include background-cover;
  text-align: center;
  color: white;

  .intro-text {
    padding-top: 100px;
    padding-bottom: 50px;
    z-index: 10;

    .intro-lead-in {
      @include serif-font;
      font-style: italic;
      font-size: 25px;
      line-height: 22px;
      margin-bottom: 25px;
    }

    .intro-heading {
      @include heading-font;
      font-weight: 600;
      font-size: 50px;
      line-height: 50px;
      margin-bottom: 25px;
    }
  }
}
@media(min-width:768px) {
  header {
    .intro-text {
      padding-top: 300px;
      padding-bottom: 200px;

      .intro-lead-in {
        @include serif-font;
        font-style: italic;
        font-size: 25px;
        line-height: 40px;
        margin-bottom: 25px;
      }

      .intro-heading {
        @include heading-font;
        font-weight: 600;
        font-size: 50px;
        line-height: 75px;
        margin-bottom: 50px;
      }
    }
  }
}
