// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------
// Footer
footer {
  padding: 25px 0;
  text-align: center;

  span.copyright {
    line-height: 40px;
    @include heading-font;
    text-transform: none;
  }

  ul.quicklinks {
    margin-bottom: 0;
    line-height: 40px;
    @include heading-font;
    text-transform: none;
  }
}
