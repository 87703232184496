// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

// Restyled Primary Buttons
.btn-primary {
  @include button-variant(white, $theme-primary, $theme-primary);
  @include heading-font;
  font-weight: 700;
}

.btn-xl {
  @include button-variant(white, $theme-primary, $theme-primary);
  @include heading-font;
  font-weight: 700;
  border-radius: 3px;
  font-size: 18px;
  padding: 20px 40px;
}

.btn-round {
  border-radius: 300px;
}

.btn-top {
    position: fixed !important;
    right: 30px;
    top: 100px;
    width: 55px;
    height: 55px;
    z-index: 0;

    .btn-text
    {
        margin-left: -5px;
    }
}

// Social Buttons
ul.social-buttons {
  margin-bottom: 0;

  li {
    a {
      display: block;
      background-color: $beehive-dark-brown;
      height: 40px;
      width: 40px;
      border-radius: 100%;
      font-size: 20px;
      line-height: 40px;
      color: white;
      outline: none;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      transition: all 0.3s;

      &:active,
      &:focus,
      &:hover {
        background-color: $theme-primary;
      }
    }
  }
}

.btn.active,
.btn:active,
.btn:active:focus,
.btn:focus {
  outline: none;
}

.portfolio-modal {
  .modal-dialog {
    margin: 0;
    height: 100%;
    width: auto;
  }

  .modal-content {
    border-radius: 0;
    background-clip: border-box;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
    min-height: 100%;
    padding: 100px 0;
    text-align: center;

    h2 {
      margin-bottom: 15px;
      font-size: 3em;
    }

    p {
      margin-bottom: 30px;
    }

    p.item-intro {
      margin: 20px 0 30px;
      @include serif-font;
      font-style: italic;
      font-size: 16px;
    }

    ul.list-inline {
      margin-bottom: 30px;
      margin-top: 0;
    }

    img {
      margin-bottom: 30px;
    }
  }

  .close-modal {
    position: absolute;
    width: 75px;
    height: 75px;
    background-color: transparent;
    top: 25px;
    right: 25px;
    cursor: pointer;

    &:hover {
      opacity: 0.3;
    }

    .lr {
      height: 75px;
      width: 1px;
      margin-left: 35px;
      background-color: $gray-darker;
      transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      /* IE 9 */
      -webkit-transform: rotate(45deg);
      /* Safari and Chrome */
      z-index: 1051;

      .rl {
        height: 75px;
        width: 1px;
        background-color: $gray-darker;
        transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        /* IE 9 */
        -webkit-transform: rotate(90deg);
        /* Safari and Chrome */
        z-index: 1052;
      }
    }
  }

  .modal-backdrop {
    opacity: 0;
    display: none;
  }
}

.contact-circle
{
    display: inline-block;
    margin: 20px;
    padding: 5px;
    //background-color: rgba(white,.5);
    border-radius: 50px;

    @media only screen and (max-width: 750px) {
      margin-left: -10px;
    }
}
.contact-circle-agri
{
    @extend .contact-circle;
    //background-color: rgba($green-light,.3);
    .contact-circle-info
    {
        color: darken($green-mid, 6);
    }
    &:hover
    {
        background-color: rgba($green-light,.3);
    }
}
.contact-circle-fb
{
    @extend .contact-circle;
    //background-color: rgba($brand-facebook,.4);
    .contact-circle-info
    {
        color: darken($brand-facebook, 5);
    }
    &:hover
    {
        background-color: rgba($brand-facebook,.2);
    }
}
.contact-circle-line
{
    @extend .contact-circle;
    //background-color: rgba($brand-line,.4);
    .contact-circle-info
    {
        color: darken($brand-line, 15);
    }
    &:hover
    {
        background-color: rgba($brand-line,.3);
    }
}
.contact-circle-beehive
{
    @extend .contact-circle;
    //background-color: rgba($yellow,.5);
    .contact-circle-info
    {
        color: $icon-paper;
    }
    &:hover
    {
        background-color: rgba($yellow,.4);
    }
}

.contact-circle-icon
{
    display: inline-block;
    vertical-align: middle;
    border-radius: 100%;
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    background-size: 100%;
}
.contact-circle-icon-agri
{
    @extend .contact-circle-icon;
    background-image: url('../images/logos/icon_agri_2.png');
    background-color: rgba($green-mid,.4);
    background-position: (3px 0px);
    background-size: 90%;
}
.contact-circle-icon-fb
{
    @extend .contact-circle-icon;
    background-image: url('../images/logos/facebook.png');
    background-color: $brand-facebook;
    background-position: (-5px -5px);
}
.contact-circle-icon-line
{
    @extend .contact-circle-icon;
    background-image: url('../images/logos/line.png');
    background-color: $brand-line;
    background-position: (-2px -5px);
    background-size: 110%;
}
.contact-circle-icon-beehive
{
    @extend .contact-circle-icon;
    background-image: url('../images/logos/icon_beehive.png');
    background-color: $yellow;
    background-position: (5px -2px);
    background-size: 80%;
}

.contact-circle-info
{
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
    margin-right: 15px;
    font-size: 12pt;
    font-weight: 300;

    @media only screen and (max-width: 750px) {
      font-size: 11pt;
    }
    @media only screen and (max-width: 320px) {
      font-size: 9pt;
    }
}
