// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

//Beehive Colors
$beehive-dark-blue: #0891ab;
$beehive-light-blue: #85ccc4;
$beehive-gray-blue: #b9d0ba;
$beehive-dark-yellow: #f8be46;
$beehive-normal-yellow: #fcdd82;
$beehive-light-yellow: #ffe942;
$beehive-dark-brown: #604c3f;
$beehive-gray-brown: #736256;
$beehive-light-brown: #c4ae9a;
$beehive-lighter-brown: lighten($beehive-light-brown, 20%);
$beehive-orange: #f2720a;


$beehive-succes: #9fd1ab;
$beehive-warning: $beehive-normal-yellow;
$beehive-danger: #ef767a;

// Gray and Brand Colors for use across theme
// $theme-primary: #fed136;
$theme-primary: $beehive-normal-yellow;
$theme-danger: #e74c3c;

$gray-base: #000 !default;
$gray-darker:	lighten($gray-base, 13.5%) !default; // #222
$gray-dark:	lighten($gray-base, 20%) !default;   // #333
$gray: lighten($gray-base, 33.5%) !default; // #555
$gray-light: lighten($gray-base, 46.7%) !default; // #777
$gray-lighter: lighten($gray-base, 93.5%) !default; // #eee


/// Regular font family
/// @type List
$text-font-stack: 'Open Sans', 'Helvetica Neue Light', 'Helvetica Neue', 'Helvetica', 'Arial', "文泉驛正黑","WenQuanYi Zen Hei","儷黑 Pro","LiHei Pro",Meiryo,"微軟正黑體","Microsoft JhengHei",sans-serif !default;

/// Code (monospace) font family
/// @type List
$code-font-stack: 'Courier New', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Monaco', monospace !default;





/// Copy text color
/// @type Color
$text-color: $beehive-dark-brown !default;

/// Main brand color
/// @type Color
$brand-color: rgb(229, 0, 80) !default;

/// Light grey
/// @type Color
$light-grey: rgb(237, 237, 237) !default;

/// Medium grey
/// @type Color
$mid-grey: rgb(153, 153, 153) !default;

/// Dark grey
/// @type Color
$dark-grey: rgb(68, 68, 68) !default;


$yellow:           #F0C851;
$pink:             #E96161;
$icon-paper:       #725047;//#bb546a;
$green:            #2a8a65;
$green-mid:        #2ea061;
$green-light:      #bff3c6;
$green-dark:       #174232;

$gray-base:        #000;
$gray-darker:      lighten($gray-base, 13.5%); // #222
$gray-dark:        lighten($gray-base, 20%);   // #333
$gray:             lighten($gray-base, 33.5%); // #555
$gray-light:       lighten($gray-base, 46.7%); // #777
$gray-lighter:     lighten($gray-base, 93.5%); // #eee

$brand-twitter:	  	#1da1f2;
$brand-line:	    	#65a33e;
$brand-facebook:  	#2b4f9c;
$brand-google-plus:	#dd4b39;


/// Container's maximum width
/// @type Length
$max-width: 1180px !default;





/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints: (
  'small': 320px,
  'medium': 768px,
  'large': 1024px,
) !default;






/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'http://cdn.example.com/assets/';
$base-url: '/build/' !default;
