
.modal-header
{
    background-color: #77bd97;
    padding: 10px 20px;

    .modal-title
    {
        font-weight: 400;
        font-size: 2.0rem;
    }

    .close
    {
        font-size: 2.2rem;
        color: black;
    }
}

.modal-body
{
    padding: 25px 25px;
    p
    {
        text-align: left;
        font-size: 1.3rem;
    }
    .agri
    {
        color: $green;
        font-weight: 400;
    }
}
